<template>
  <ion-page>

    <!-- Content -->
    <ion-content>
      <!-- Header -->
      <ModalHeader page="profile-search" />
      <!-- Content Container -->
      <div class="app-max-width">
        <div class="bg-light" style="padding:2px;">
          <!-- Profile Type -->
          <ion-segment scrollable :value="profileType">
            <ion-segment-button value="all" @click.prevent="updateProfileType('all')">All</ion-segment-button>
            <ion-segment-button value="people" @click.prevent="updateProfileType('user')">People</ion-segment-button>
            <ion-segment-button value="artists" @click.prevent="updateProfileType('artist')">Artists</ion-segment-button>
            <ion-segment-button value="venues" @click.prevent="updateProfileType('venue')">Venues</ion-segment-button>
            <ion-segment-button value="pages" @click.prevent="updateProfileType('business')">Pages</ion-segment-button>
            <!--<ion-segment-button value="orgs">Organization</ion-segment-button>-->
          </ion-segment> 
        </div>
        <div class="padding-right-md padding-bottom-md">
          <!-- Clear Search -->
          <ion-item>
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-input v-model="keyword" placeholder="Enter a name to search"></ion-input>
            <ion-button color="light" v-if="keyword != null && keyword != ''" @click.prevent="clearSearch()">
              <ion-icon :icon="close" color="dark"></ion-icon>
            </ion-button>
          </ion-item>

          <!-- No Keyword -->
          <div v-if="keyword === null">
            <!-- Collection List -->
            <div v-if="profileType == 'all'">
              <ion-list>
                <ion-item v-for="user in userCollection" :key="user" @click.prevent="goToProfile(user.username)" style="cursor:pointer;">
                  <div tabindex="0"></div><!-- bug fix for safari 14 -->
                  <ion-avatar slot="start">
                    <img v-if="user.profilePhoto != null && user.profilePhoto != ''" :src="user.profilePhoto">
                    <img  v-else src="https://s3-us-west-1.amazonaws.com/artistwavesoftware/users/default.jpg">
                  </ion-avatar>
                  <ion-label>
                    <h2>{{user.name}}</h2>
                    <!--<h3>Guitarist in metal band</h3>-->
                    <p>{{user.location}}</p>
                  </ion-label>
                </ion-item>
              </ion-list>       
            </div>
            <div v-else>
              <!-- Query Results -->
              <div v-if="matchedItems.length > 0">
                <ion-list>
                  <ion-item v-for="user in matchedItems" :key="user" @click.prevent="goToProfile(user.username)" style="cursor:pointer;">
                    <div tabindex="0"></div><!-- bug fix for safari 14 -->
                    <ion-avatar slot="start">
                      <img v-if="user.profilePhoto != null && user.profilePhoto != ''" :src="user.profilePhoto">
                      <img  v-else src="https://s3-us-west-1.amazonaws.com/artistwavesoftware/users/default.jpg">
                    </ion-avatar>
                    <ion-label>
                      <h2>{{user.name}}</h2>
                      <!--<h3>Guitarist in metal band</h3>-->
                      <p>{{user.location}}</p>
                    </ion-label>
                  </ion-item>
                </ion-list>
              </div>
              <!-- Query Results -->
              <div v-else class="padding-md text-medium">
                There are no profiles for this profile type.
              </div>
            </div>
          </div>
          <!-- Keyword -->
          <div v-else>
            <!-- Collection List -->
            <div v-if="profileType == 'all' && keyword.length > 0">
              <div v-if="matchedItems.length > 0">
                <ion-list>
                  <ion-item v-for="user in matchedItems" :key="user" @click.prevent="goToProfile(user.username)" style="cursor:pointer;">
                    <div tabindex="0"></div><!-- bug fix for safari 14 -->
                    <ion-avatar slot="start">
                      <img v-if="user.profilePhoto != null && user.profilePhoto != ''" :src="user.profilePhoto">
                      <img  v-else src="https://s3-us-west-1.amazonaws.com/artistwavesoftware/users/default.jpg">
                    </ion-avatar>
                    <ion-label>
                      <h2>{{user.name}}</h2>
                      <!--<h3>Guitarist in metal band</h3>-->
                      <p>{{user.location}}</p>
                    </ion-label>
                  </ion-item>
                </ion-list>
              </div>
              <div v-else class="padding-md text-medium">
                There are no profiles.
              </div>
            </div>
            <div v-else>
              <div v-if="matchedItems.length > 0">
                <ion-list>
                  <ion-item v-for="user in matchedItems" :key="user" @click.prevent="goToProfile(user.username)" style="cursor:pointer;">
                    <div tabindex="0"></div><!-- bug fix for safari 14 -->
                    <ion-avatar slot="start">
                      <img v-if="user.profilePhoto != null && user.profilePhoto != ''" :src="user.profilePhoto">
                      <img  v-else src="https://s3-us-west-1.amazonaws.com/artistwavesoftware/users/default.jpg">
                    </ion-avatar>
                    <ion-label>
                      <h2>{{user.name}}</h2>
                      <!--<h3>Guitarist in metal band</h3>-->
                      <p>{{user.location}}</p>
                    </ion-label>
                  </ion-item>
                </ion-list>
              </div>
              <div v-else class="padding-md text-medium">
                There are no profiles for this profile type.
              </div>        
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonInput, IonSegment, IonSegmentButton, IonAvatar, IonLabel, IonList, IonItem, IonIcon, IonButton } from '@ionic/vue';
import ModalHeader from '../../components/ModalHeader.vue';
import { close } from 'ionicons/icons';
import { defineComponent, ref, onMounted, computed } from 'vue';
import apiClient from '../../services/api';
import { useStore } from "vuex";
import doAuth from '../../services/auth';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'ProfileSearch',
  props: [],
  components: { IonPage, IonContent, IonInput, IonSegment, IonSegmentButton, IonAvatar, IonLabel, IonList, IonItem, IonIcon, IonButton, ModalHeader },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();
    const userCollection = ref([]);
    const store = useStore();
    const authUser = computed(() => store.state.authUser);
    const authToken = computed(() => store.state.authToken);
    const appId = computed(() => store.state.appId);
    const profileType = ref('all');
    const keyword = ref(null);
    const matchedItems = ref([]);

    onMounted(() => {
      getUserCollection()
    })

    function updateProfileType(val) {
      profileType.value = val
    }

    function searchUsersByKeyword() {
      if(keyword.value != null && keyword.value != '') {
        matchedItems.value = []
        var str = keyword.value
        for(var i=0; i<userCollection.value.length;i++) {
          var username = userCollection.value[i].username.toLowerCase()
          var name = userCollection.value[i].name.toLowerCase()
          var pType = userCollection.value[i].profile_type.toLowerCase()
          str = str.toLowerCase()

          if(profileType.value === 'all') {
            if(username.includes(str) || name.includes(str)) {
              matchedItems.value.push({
                id: userCollection.value[i].id,
                name: userCollection.value[i].name,
                username: userCollection.value[i].username,
                profilePhoto: userCollection.value[i].profilePhoto,
                location: userCollection.value[i].location,
                profile_type: userCollection.value[i].profile_type                
              })
            }
          } else {
          
            if(username.includes(str) || name.includes(str)) {
              if(pType === profileType.value) {
                matchedItems.value.push({
                  id: userCollection.value[i].id,
                  name: userCollection.value[i].name,
                  username: userCollection.value[i].username,
                  profilePhoto: userCollection.value[i].profilePhoto,
                  location: userCollection.value[i].location,
                  profile_type: userCollection.value[i].profile_type                
                })
              }
            }
          }

        }
      }     
    }

    function searchUsersByProfileType() {
      matchedItems.value = []
      for(var i=0; i<userCollection.value.length;i++) {
        var pType = userCollection.value[i].profile_type.toLowerCase()

        if(profileType.value === 'all') {
        
          matchedItems.value.push({
            id: userCollection.value[i].id,
            name: userCollection.value[i].name,
            username: userCollection.value[i].username,
            profilePhoto: userCollection.value[i].profilePhoto,
            location: userCollection.value[i].location,
            profile_type: userCollection.value[i].profile_type                
          })
          
        } else {
          if(pType === profileType.value) {
            matchedItems.value.push({
              id: userCollection.value[i].id,
              name: userCollection.value[i].name,
              username: userCollection.value[i].username,
              profilePhoto: userCollection.value[i].profilePhoto,
              location: userCollection.value[i].location,
              profile_type: userCollection.value[i].profile_type                
            })
          }
          
        }

      }
            
    }

    function clearSearch() {
      this.keyword = null
      this.matchedItems = []
      this.profileType = 'all'
    }

    function getUserCollection() {
      apiClient.get('/api/get/profile/collection',
        {
          headers: {
            'Authorization':  `Bearer ${authToken.value}`
          }
        }).then(response => {
          //userCollection.value = response.data
          for(var i=0;i<response.data.length;i++) {
            var location = null
            if(response.data[i].location != null && response.data[i].location != '') {
              location = response.data[i].location
            }

            userCollection.value.push({
              id: response.data[i].id,
              name: response.data[i].name,
              username: response.data[i].username,
              profilePhoto: response.data[i].profile_photo,
              location: location,
              profile_type: response.data[i].profile_type
            })
          }
        }).catch(error => {
          console.log(error)
        });        
    }
    return {
      userCollection, authUser, appId, updateProfileType, searchUsersByKeyword, searchUsersByProfileType, clearSearch, getUserCollection, profileType, keyword, matchedItems, close, router
    }
  },
  methods: {
    goToProfile: function(profile) {
      this.router.push('/profile/'+profile)
    }
  },
  watch: {
    keyword: function() {
      if(this.keyword == '') {
        this.keyword = null
      }
      if(this.keyword === null) {
        this.matchedItems = []
      } else {
        this.searchUsersByKeyword()
      }
    },
    profileType: function() {
      this.matchedItems = []
      if(this.keyword === null || this.keyword == '') {
        this.searchUsersByProfileType()
      } else {
        this.searchUsersByKeyword()
      }
    }
  }
});
</script>
<style scoped>
ion-segment {
  --background:#f4f5f8!important;
}
</style>